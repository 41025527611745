import { Box } from 'theme-ui';
import { useEffect, useState, useRef } from 'react';
import PlayIcon from '~/assets/images/icons/play-button.svg';
import videojs from '@mux/videojs-kit';
import '@mux/videojs-kit/dist/index.css';
import { useMuxVideoAnalytics } from '~/hooks/utils/customAnalytics';
import { useIntersectionObserver } from '~/hooks/components/use-intersection-observer';

/**
 * Implements a video player using MUX and videojs
 * Based on example from https://videojs.com/guides/react/
 *
 */

const ArticleMuxVideo = ({ reference }) => {
  const {
    video: { playbackId, ratio },
    name,
  } = reference;

  const [isPlaying, setIsPlaying] = useState(false);

  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const playerInitTime = Date.now();
  const muxOptions = {
    aspectRatio: ratio,
    responsive: true,
    controls: true,
    nativeControlsForTouch: true,
    reportTouchActivity: true,
    plugins: {
      mux: {
        debug: process.env.NODE_ENV !== 'production',
        data: {
          env_key: process.env.GATSBY_MUX_DATA,
          player_name: 'Article Player',
          player_init_time: playerInitTime,
          video_id: playbackId,
          video_title: name,
        },
      },
    },
  };

  const handlePlayerReady = async (player) => {
    playerRef.current = player;
    player.src({ src: `${playbackId}`, type: 'video/mux' });
    player.on('ended', () => {
      setIsPlaying(false);
    });
    player.on('play', () => {
      setIsPlaying(true);
    });
    player.on('pause', () => {
      setIsPlaying(false);
    });
  };

  /**
   * Initialize video player
   */
  useEffect(() => {
    if (!playerRef?.current) {
      const player = (playerRef.current = videojs(videoRef.current, muxOptions, () => {
        handlePlayerReady(player);
      }));
    }
  }, [videoRef]);

  /**
   * Cleanup video player on unmount
   */
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  const isVisible = useIntersectionObserver(videoRef, 0.8);

  /**
   * Play/pause video when it leaves/enters viewport
   */
  useEffect(() => {
    const player = playerRef?.current;
    if (!isVisible) {
      player?.pause();
    }
  }, [isVisible]);

  useMuxVideoAnalytics({
    playerRef: playerRef,
    id: playbackId,
    title: name,
    locationDetail: 'article_player',
  });

  return (
    <Box
      sx={{
        px: ['2.4rem', '4rem'],
        position: 'relative',
        mb: '4.8rem',
      }}
    >
      <PlayIcon
        sx={{
          display: ['none', 'block'],
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1,
          opacity: isPlaying ? 0 : 1,
          transition: 'opacity 0.2s ease',
          pointerEvents: 'none', // event handler is on video element
        }}
      />
      <video
        ref={videoRef}
        className="video-js vjs-matrix vjs-big-play-centered"
        playsInline
        preload="auto"
        sx={{
          cursor: 'pointer',
          transform: 'translateZ(0px)',
          transformStyle: 'preserve-3d',
        }}
      />
    </Box>
  );
};

export default ArticleMuxVideo;
