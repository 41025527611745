import { useState, useEffect } from 'react';

export const useIntersectionObserver = (passedRef, threshold, topMargin = null, defaultState) => {
  const [isIntersecting, setIsIntersecting] = useState(defaultState ?? false);
  useEffect(() => {
    const ref = passedRef;
    if (!ref?.current || !window.IntersectionObserver) {
      return;
    }

    const visibleThreshold = threshold || 0.5;

    const options = {
      root: null, // null will target the viewport
      rootMargin: `${topMargin ?? 0}px 0px 0px 0px`,
      threshold: visibleThreshold,
    };
    const observer = new window.IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.intersectionRatio > visibleThreshold);
    }, options);

    observer.observe(ref.current);

    return () => {
      if (ref?.current) {
        observer.unobserve(ref.current);
      }
      observer.disconnect();
    };
  }, [passedRef, threshold, topMargin]);

  return isIntersecting;
};
